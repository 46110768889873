import { mapState } from "vuex";

export default {
  methods: {
    gtmAll() {
      let items = this.$session.get('items')

      if (!items) {
        return this.cart.items;
      }

      return items;
    },
    gtmFind(id) {
      return this.gtmAll().find(item => item.id === id);
    },
    gtmFindIndex(id) {
      return this.gtmAll().findIndex(item => item.id === id);
    },
    gtmTransform(item, index) {
      if (!item) {
        return {};
      }

      const amount = item.rates.reduce((previous, current) => {
        return parseFloat(previous) + parseFloat(current.amount);
      }, 0)

      return {
        index: index,
        price: amount,
        quantity: item.quantity,
        item_id: item.id,
        item_name: item.room_type_name
      };
    },
    gtmTransformCollection(collection) {
      let result = [];

      collection.map(item => {
        result.push(this.gtmTransform(
          this.gtmFind(item.id),
          this.gtmFindIndex(item.id),
        ));
      });

      return result;
    },
    gtmReset() {
      this.$gtm.push({
        ecommerce: null
      });
    },
    gtmGetValues() {
      const items = this.gtmAll();
      let currency = 'EUR';
      let total = 0;

      items.forEach(item => {
        currency = item.currency;

        item.rates.forEach(rate => {
          total += parseFloat(rate.amount)
        })
      });

      return {
        currency: currency,
        value: total,
      }
    },
    async gtmAddToCart(id) {
      this.gtmReset();
      this.$gtm.push({
        event: 'add_to_cart',
        ecommerce: {
          items: [this.gtmTransform(
            this.gtmFind(id),
            this.gtmFindIndex(id),
          )],
          ...this.gtmGetValues(),
        },
      });
    },
    async gtmRemoveFromCart(id) {
      this.gtmReset();
      this.$gtm.push({
        ecommerce: null
      });
      this.$gtm.push({
        event: 'remove_from_cart',
        ecommerce: {
          items: [this.gtmTransform(
            this.gtmFind(id),
            this.gtmFindIndex(id),
          )],
          ...this.gtmGetValues(),
        },
      });
    },
    async gtmAddPaymentInfo() {
      this.$gtm.push({
        ecommerce: null
      });
      this.$gtm.push({
        event: 'add_payment_info',
        ecommerce: {
          items: this.gtmTransformCollection(this.gtmAll()),
          ...this.gtmGetValues(),
        },
      });
    },
    async gtmBeginCheckout() {
      this.$gtm.push({
        ecommerce: null
      });
      this.$gtm.push({
        event: 'begin_checkout',
        ecommerce: {
          items: this.gtmTransformCollection(this.gtmAll()),
          ...this.gtmGetValues(),
        }
      });
    },
    async gtmPurchase() {
      this.$gtm.push({
        ecommerce: null
      });
      this.$gtm.push({
        event: 'purchase',
        ecommerce: {
          items: this.gtmTransformCollection(this.gtmAll()),
          ...this.gtmGetValues(),
        }
      });
    }
  },
  computed: {
    ...mapState({
      cart: state => state.cart,
    }),
  }
}
