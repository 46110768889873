const SCRIPT_SRC = 'https://js.stripe.com/v3/';

export class StripeWrapper {
  config;
  instance;
  isAttached = false;

  constructor(config) {
    this.config = config;

    if (config.secret) {
      this.ready().then(instance => {
        this.createInstance();
      });
    }
  }

  attach(success, failure) {
    let script = document.createElement('script');
    script.setAttribute('src', SCRIPT_SRC);

    script.addEventListener('load', (e) => {
      success(e);
    }, false);

    script.addEventListener('error', (e) => {
      failure(e);
    }, false);

    document.head.appendChild(script);
  }

  ready() {
    return new Promise((resolve, reject) => {
      if (this.isAttached) {
        resolve(this.instance);
        return;
      }

      this.attach(event => {
        this.isAttached = true;
        resolve(this.instance);
      }, event => {
        reject({
          message: 'Failed to load Stripe'
        });
      })
    })
  }

  getInstance() {
    return this.instance;
  }

  createInstance() {
    if (!this.instance) {
      this.instance = Stripe(this.config.secret);
    }

    return this;
  }

  setPublishableKey(key) {
    this.instance = Stripe(key);

    return this;
  }

  createElementsInstance(options) {
    return this.instance.elements(options);
  }

  createElement(type, config = {}) {
    let elementsInstance = this.createElementsInstance(config.options || {});
    let element = elementsInstance.getElement(type);

    element = element || elementsInstance.create(type, config.elementOptions || {});
    element.mount(`#${config.elementId}`);

    return { element, instance: elementsInstance };
  }

  confirmPayment(options) {
    return this.instance.confirmPayment(options);
  }

  confirmCardSetup(secret, options) {
    return this.instance.confirmCardSetup(secret, options);
  }

  confirmSepaDebitSetup(secret, options) {
    return this.instance.confirmSepaDebitSetup(secret, options);
  }
}
