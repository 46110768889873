
import VHeader from "../components/global/VHeader";
import VFooter from "../components/global/VFooter";
import Blocks from "../components/global/Blocks";
import { env } from "@/config/.env";
import { mapState } from "vuex";

import {
  SET_BROWSER
} from '~/store/app/constants';

export default {
  components: {
    VFooter,
    VHeader,
    Blocks
  },
  head() {
    return {
      title: this.network?.name ? this.network.name : 'Booking Engine',
      meta: [
        { charset: 'utf-8' },
        { hid: 'description', name: 'description', content: this.network.description ? this.network.description : '' },
        { hid: 'keywords', name: 'keywords', content: 'book, best price, hospitality, hotel, b&b, apartments' }
      ],
      bodyAttrs: {
          class: 'has-navbar-fixed-top has-spaced-navbar-fixed-top custom-page-layout'
        },
      link: [
        {
          hid: 'icon',
          rel: 'icon',
          type: this.network.favicon ? 'image/svg+xml' : 'image/png' ,
          href: !this.network.favicon ? `data:image/svg+xml,<svg viewBox="0 0 250 250" xmlns="http://www.w3.org/2000/svg">
                                                              <circle cx="125" cy="125" r="120" fill="${encodeURIComponent(this.network.primary_color)}"/>
                                                              <text x="50%" y="52%" text-anchor="middle" fill="white" font-size="140px" font-weight="bolder" font-family="sans-serif" dy=".3em">
                                                                ${this.network.name.split(" ").shift().charAt(0)}${this.network.name.split(" ").pop().charAt(0)}
                                                              </text>
                                                            </svg>`
                                                            :
                                                            this.network.favicon.full_path,
        },
        {
          //HEADERS FONT LOADER
          rel: 'stylesheet',
          href: this.network.font_title.length != 0 ? this.network.font_title.link : ''
        },
        { 
          //BODY FONT LOADER
          rel: 'stylesheet',
          href: this.network.font_body.length != 0 ? this.network.font_body.link : ''
        }
      ],
    };
  },
  data() {
    return {
      googleApiKey: env.GOOGLE_API_KEY,
      ready: false,
      title_font: false,
      body_font: false,
    };
  },
  mounted() {
    this.ready = true;
    if (!this.isBrowser) {
      this.$store.commit(SET_BROWSER, true);
    }

    this.cookieLaw();
  },
  computed: {
    ...mapState({
      isBrowser: state => state.app.isBrowser,
      network: state => state['booking-networks'].getBookingNetworksRequest.data,
    }),
  }
};
