
import { env } from "@/config/.env";
import { mapState } from "vuex";

export default {
  head() {
    return {
      title: this.network?.name ? this.network.name : 'Booking Engine',
      meta: [
        { charset: 'utf-8' },
        { hid: 'description', name: 'description', content: this.network.description ? this.network.description : '' },
        { hid: 'keywords', name: 'keywords', content: 'book, best price, hospitality, hotel, b&b, apartments' }
      ],
    };
  },
  data() {
    return {
      googleApiKey: env.GOOGLE_API_KEY,
      ready: false,
    };
  },
  computed: {
    ...mapState({
      isBrowser: state => state.app.isBrowser,
      network: state => state['booking-networks'].getBookingNetworksRequest.data,
    }),
  }
};
