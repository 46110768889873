import {
  GET_BOOKING_NETWORK_BLOCKS
} from '@/store/booking-networks/constants';

export default async function ({ store, redirect, route }) {
  const currentRoute = route.name.split('___')[0];

  await store.dispatch(GET_BOOKING_NETWORK_BLOCKS, {
    page: currentRoute
  });
}
