import {
  GET_BOOKING_NETWORKS,
} from "@/store/booking-networks/constants";

import {
  GET_CONFIG,
  GET_CONSTANTS,
} from '@/store/data/constants';

export default {
  methods: {
    async changeLanguage(code) {
      await this.$router.push(this.switchLocalePath(code));
      await this.$i18n.setLocale(code);
      await this.$store.dispatch(GET_CONFIG);
      await this.$store.dispatch(GET_CONSTANTS);
      await this.$store.dispatch(GET_BOOKING_NETWORKS);
    }
  }
}
