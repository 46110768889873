
import { mapState } from "vuex";

import {
  SET_BROWSER
} from '~/store/app/constants';

export default {
  head() {
    return {
      title: 'Customer Portal',
      meta: [
        { charset: 'utf-8' },
        { hid: 'keywords', name: 'keywords', content: 'book, best price, hospitality, hotel, b&b, apartments' }
      ],
    };
  },
  data() {
    return {
      ready: false,
      title_font: false,
      body_font: false,
    };
  },
  mounted() {
    this.ready = true;

    if (!this.isBrowser) {
      this.$store.commit(SET_BROWSER, true);
    }
  },
  computed: {
    ...mapState({
      isBrowser: state => state.app.isBrowser,
    }),
  }
};
