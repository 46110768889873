
import VHeader from "../components/global/VHeader";
import VFooter from "../components/global/VFooter";
import { env } from "@/config/.env";
import { mapState } from "vuex";

import {
  SET_BROWSER
} from '~/store/app/constants';

export default {
  components: {
    VFooter,
    VHeader,
  },
  head() {
    return {
      title: this.network?.name ? this.network.name : 'Booking Engine',
      meta: [
        { charset: 'utf-8' },
        { hid: 'description', name: 'description', content: this.network.description ? this.network.description : '' },
        { hid: 'keywords', name: 'keywords', content: 'book, best price, hospitality, hotel, b&b, apartments' }
      ],
      link: [
        {
          hid: 'icon',
          rel: 'icon',
          type: 'image/x-icon',
          href: this.network.favicon ? this.network.favicon.full_path : '/favicon.ico'
        }
      ]
      // script: [
      //   {
      //     src: `https://maps.googleapis.com/maps/api/js?key=${this.googleApiKey}&libraries=places`,
      //   },
      // ],
    };
  },
  data() {
    return {
      googleApiKey: env.GOOGLE_API_KEY,
      ready: false,
    };
  },
  mounted() {
    this.ready = true;
    if (!this.isBrowser) {
      this.$store.commit(SET_BROWSER, true);
    }
  },
  computed: {
    ...mapState({
      isBrowser: state => state.app.isBrowser,
      network: state => state['booking-networks'].getBookingNetworksRequest.data,
    }),
  }
};
