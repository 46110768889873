import { mapState } from 'vuex';
import { objectFlip } from "@/utils/helpers";

import {
  isArray,
  isObject,
  cloneDeep,
} from 'lodash';

import {
  filterObject
} from "@/utils/helpers";

/**
 * @type {string[]}
 */
const arrayAliases = [
  'rt',
  'am',
  'cha',
];

/**
 * @param aliases
 * @param params
 * @returns {{}}
 */
const buildQuery = (aliases, params) => {
  let query = {};

  for (let k in params) {
    if (!params.hasOwnProperty(k)) {
      continue;
    }

    let key = typeof aliases[k] === 'undefined' ? k : aliases[k];
    query[key] = params[k];

    if (
      arrayAliases.indexOf(k) > -1 &&
      !isArray(params[k]) &&
      !isObject(params[k])
    ) {
      query[key] = [params[k]];
    }
  }

  return query;
};

export default {
  computed:{
    ...mapState({
      aliases: state => state.data.getConfigRequest.data.aliases || [],
    }),
  },
  methods:{
    toAliases(params) {
      return buildQuery(this.aliases, params);
    },
    fromAliases(params) {
      return buildQuery(objectFlip(this.aliases), params);
    },
    fromAliasesFiltered(params, filters) {
      let fromAliases = this.fromAliases(params);

      fromAliases = { ...fromAliases, ...filters };

      return filterObject(cloneDeep(fromAliases));
    },
    toAliasesFiltered(params, filters) {
      return this.toAliases(this.fromAliasesFiltered(params, filters));
    },
  }
}
