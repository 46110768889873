import { mapState } from 'vuex';

export default {
  computed: {
    ...mapState({
      constants: state => state.data.getConstantsRequest.data,
    })
  },
  methods: {
    $in(constant, excludes = []) {
      let values = this.constants[constant] || [];
      
      if (excludes.length) {
        values = values.filter((item) => {
            if (!excludes.includes(item.name)) {
              return item;
            }
        })
      }

      return values;
    },
    $const(constantsClass, constant, isReverse) {
      const constants = this.$in(constantsClass);

      let attribute = isReverse ? 'text' : 'value';

      for (let i = 0; i < constants.length; i++) {
        if (constants[i].name === constant) {
          return constants[i][attribute];
        }
      }

      return null;
    },
    $constText(constantsClass, value) {
      const constants = this.$in(constantsClass);

      for (let i = 0; i < constants.length; i++) {
        if (constants[i].value === value) {
          return constants[i].text;
        }
      }

      return null;
    },
    $localize(data, fallback = '') {
      if (!data) {
        return fallback;
      }
      if (!!data[this.$i18n.locale]) {
        return data[this.$i18n.locale];
      }

      return !!data[this.defaultLocale] ? data[this.defaultLocale] : fallback;
    },
    $money(amount, currency, format) {
      if (!amount) {
        return 0;
      }

      if (!format) {
        format = this.$i18n.locale;
      }

      if (!currency) {
        return parseFloat(parseFloat(amount).toFixed(2));
      }

      return this.$formatMoney(amount, currency, format);
    }
  }
}
