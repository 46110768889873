export default function ({ store, redirect, route }) {
  const dynamicPages = [
    { key: 'home', route: 'index' },
    { key: 'about_us', route: 'about-us' },
    { key: 'promotions', route: 'promotions' },
    { key: 'properties_list', route: 'properties-list' },
  ];

  const network = store.state['booking-networks'].getBookingNetworksRequest.data;

  if (!network.dynamic_pages) {
    return;
  }

  const currentRoute = route.name.split('___')[0];
  const dynamicPage = dynamicPages.filter(page => page.route === currentRoute)[0];

  if (!!dynamicPage && network.dynamic_pages.indexOf(dynamicPage.key) <0) {
    redirect('404');
  }
}
