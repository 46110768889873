import { mapState } from 'vuex';

import cart from "./cart";
import router from "./router";
import aliases from "./aliases";
import datepicker from "./datepicker";

import {
  isArray,
  cloneDeep,
} from "lodash";

import {
  SEARCH_SET,
  SEARCH_RESET,
  SEARCH_DATA,
  SEARCH_SINGLE,
  SEARCH_SINGLE_BY_SLUG,
  SEARCH_COMBINATIONS,
} from '~/store/search/constants';

export default {
  mixins: [
    cart,
    router,
    aliases,
    datepicker,
  ],
  data() {
    return {
      filters: {},
      endDate: null,
      startDate: null,
      isShowChildrenAges: false,
    };
  },
  created() {
    this.initFilters();
  },
  methods: {
    async submitSearch(params) {
      if (!params.skipEmptyCart) {
        await this.emptyCart();
      }

      params = params || {};

      let fromAliases = this.fromAliasesFiltered(this.$route.query, this.filters);
      let toAliases = this.toAliases(fromAliases);
      let datesChanged = this.isDatesChanged(fromAliases);

      if (datesChanged) {
        toAliases.page = 1;
      }

      if (params.page) {
        toAliases.page = params.page;
      }

      if (params.redirect === 'single') {
        await this.$router.push(this.singleRoute(params.id, toAliases));
      }
      if (params.redirect === 'properties') {
        await this.$router.push(this.propertiesRoute(toAliases));
      }

      if (params.data === true) {
        await this.$store.dispatch(SEARCH_DATA, toAliases);
      }
      if (params.single === true) {
        if (isFinite(params.id) && !isNaN(parseFloat(params.id))) {
          await this.$store.dispatch(SEARCH_SINGLE, { id: params.id, ...toAliases });
        } else {
          await this.$store.dispatch(SEARCH_SINGLE_BY_SLUG, { slug: params.id, ...toAliases });
        }
      }
      if (params.combinations === true) {
        await this.$store.dispatch(SEARCH_COMBINATIONS, { id: this.property.id, ...toAliases }).then(response => {
          let isSuggestion = false;

          response.map(item => {
            if (item.is_suggestion) {
              isSuggestion = true;
            }
          })

          if (isSuggestion && process.client) {
            this.suggestionToast()
          }
        });
      }

      await this.$store.commit(SEARCH_SET, fromAliases);
    },
    initFilters() {
      let fromAliases = this.fromAliases(this.$route.query);

      for (let item in fromAliases) {
        if (!fromAliases.hasOwnProperty(item)) {
          continue;
        }

        if (!isNaN(fromAliases[item]) && !isArray(fromAliases[item])) {
          fromAliases[item] = parseInt(fromAliases[item]);
        }
      }

      this.filters = cloneDeep({ ...this.searchFilters, ...fromAliases });

      if (this.filters.end_date) {
        this.endDate = this.$moment(this.filters.end_date).toDate();
      }
      if (this.filters.start_date) {
        this.startDate = this.$moment(this.filters.start_date).toDate();
      }
      if (this.filters.ref) {
        this.$session.setCookie('ref', this.filters.ref, {
          path: '/',
          expires: new Date((new Date()).getTime() + (60 * 60 * 24 * 1000))
        });
      }
    },
    startDateChanged() {
      this.endDate = this.$moment(this.startDate).add(1, "day").toDate();
      this.$refs.endDate.toggle();
    },
    isDatesChanged(params) {
      const oldStartDate = this.filters.start_date;
      const oldEndDate = this.filters.end_date;

      return oldStartDate !== params.start_date || oldEndDate !== params.end_date;
    },
    prevPage() {
      if (this.pagination.page === 1) {
        return;
      }

      this.paginate(this.pagination.page - 1);
    },
    nextPage() {
      if (this.pagination.is_last) {
        return;
      }

      this.paginate(this.pagination.page + 1);
    },
    suggestionToast(){
      this.$buefy.toast.open({
        duration: 5000,
        message: this.$t('properties.search.no_results_suggestion_short'),
        position: 'is-bottom',
        type: 'is-danger'
      });
    },
  },
  computed: {
    getNetworkConfig() {
      if (!this.network.config) return {};
      return JSON.parse(this.network.config)
    },
    ages() {

      let maxChildAges = parseInt(this.getNetworkConfig?.maxChildAges);
      if (isNaN(maxChildAges) || maxChildAges <= 0 || !!maxChildAges) {
        maxChildAges = 17;
      }

      return Array.from({ length: maxChildAges }, (v, k) => k + 1);
    },
    nextDay() {
      return this.$moment(this.startDate).add(1, 'day').toDate()
    },
    searchFiltersFormatted() {
      return {
        year: this.$moment(this.searchFilters.end_date).format('YYYY').toString(),
        to_date: this.$moment(this.searchFilters.end_date).format('DD MMM').toString(),
        from_date: this.$moment(this.searchFilters.start_date).format('DD MMM').toString(),
        guests: this.searchFilters.adults + this.searchFilters.children,
        nights: this.searchFilters.start_date && this.searchFilters.end_date ?
          this.$moment(this.searchFilters.end_date).diff(this.$moment(this.searchFilters.start_date), 'days') : 1,
      }
    },
    maxGuests() {
      return 50;
      //return this.configData.max_occupation ? parseInt(this.configData.max_occupation) : 999;
    },
    ...mapState({
      searchFilters: state => state.search.filters,
      loading: state => state.search.dataRequest.loading,
      loadingSingle: state => state.search.singleRequest.loading,
      loadingCombinations: state => state.search.combinationsRequest.loading,
      property: state => state.search.singleRequest.data,
      properties: state => state.search.dataRequest.data.collection || [],
      pagination: state => state.search.dataRequest.data.pagination || {},
      isSuggestion: state => !!state.search.dataRequest.data.suggestion,
      combinations: state => state.search.combinationsRequest.data || [],
      //configData: state => state.data.getConfigRequest.data || [],
    }),
  },
  watch: {
    'searchFilters.end_date'() {
      this.endDate = this.$moment(this.searchFilters.end_date).toDate();
    },
    'searchFilters.start_date'() {
      this.startDate = this.$moment(this.searchFilters.start_date).toDate();
    },
    'searchFilters.radius'() {
      if (
        this.searchFilters.radius &&
        this.searchFilters.latitude &&
        this.searchFilters.longitude
      ) {
        this.submitSearch({
          data: true,
          redirect: 'properties'
        });
      }
    },
    'endDate': {
      deep: true,
      handler(value) {
        this.filters.end_date = this.formatISO(value);
      }
    },
    'startDate': {
      deep: true,
      handler(value) {
        this.filters.start_date = this.formatISO(value);
      }
    },
    'filters.children': {
      handler(value) {
        this.isShowChildrenAges = !!value;

        if (value === 0) {
          this.filters.children_ages = [];
        }

        this.filters.children_ages = this.filters.children_ages.slice(0, value);
      }
    }
  }
}
