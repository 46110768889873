export default {
  methods: {
    singleFormatter(date) {
      return this.$moment(date).format(this.dateFormat);
    },
    ageFormatter(date) {
      return `${this.singleFormatter(date)} - ${this.$t('main.words.age')}: ${this.$moment().diff(this.$moment(date), 'years')}`
    },
    formatISO(date) {
      return this.$moment(date).format('YYYY-MM-DD');
    }
  },
  computed: {
    dateToday() {
      return this.$moment().subtract(1, 'days').toDate();
    },
    dateFormat() {
      return this.$moment().localeData(this.$i18n.locale).longDateFormat('ll');
    }
  }
}
