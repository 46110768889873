import Vue from 'vue';
import { mapState } from 'vuex';
import tinycolor from 'tinycolor2';
import Greview from '@/components/global/DinamicBlocks/Greview.vue'; // Importa il componente Greview


export default {
  methods: {
    cookieLaw() {
      if (!!this.$session.get('cookie_accepted')) {
        return;
      }

      this.$buefy.snackbar.open({
          indefinite: true,
          message: `<p style="font-size: 10px">${this.$t('main.cookie_policy')}</p>`,
          type: 'is-warning',
          position: 'is-bottom',
          actionText: this.$t('main.words.accept'),
          queue: false,
          onAction: () => {
              this.$session.set('cookie_accepted', true);
          }
      })
    },
    tinycolor,
    // nuova possibilità di rendering dinamico dei componenti basati sulla ricerca di un ID
    renderComponentInDiv(component, divId) {
      const div = document.getElementById(divId);
      if (div) {
        new Vue({
          parent: this,
          render: h => h(component)
        }).$mount(`#${divId}`);
      } else {
        // console.warn(`${divId} not found.`);
      }
    }
  },
  computed: {
    ...mapState({
      isBrowser: state => state.app.isBrowser,
      network: state => state['booking-networks'].getBookingNetworksRequest.data,
    }),
    isSimpleView() {
      return this.network.property_view == this.$const('property_view', 'SIMPLE');
    },
    isShowChildren() {
      return !!this.network.is_search_show_children;
    },
    isShowRooms() {
      return !!this.network.is_search_show_rooms;
    },
    isSingleProperty() {
      return !!this.network.single_property_id;
    },
    networkConfig() {
      if (!this.network.config) return {};
      
      return JSON.parse(this.network.config)
    }
  },
  mounted() {
    // esempio di render basato su ID. Si passa nome componente e poi ID tra ''
    this.renderComponentInDiv(Greview, 'google-review');
  },
  watch: {
    '$i18n.locale'(val) {
      this.$moment.locale(this.$i18n.locale);
    }
  }
}
