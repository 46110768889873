
import VHeader from "../components/global/VHeader";
import VFooter from "../components/global/VFooter";
import Blocks from "../components/global/Blocks";
import Cookies from 'universal-cookie';

import { env } from "@/config/.env";
import { mapState } from "vuex";

import {
  SET_BROWSER
} from '~/store/app/constants';

export default {
  components: {
    VFooter,
    VHeader,
    Blocks
  },
  head() {
    return {
      title: this.network?.name ? this.network.name : 'Booking Engine',
      meta: [
        { hid: 'og:title', name: 'og:title', content: this.network?.name ? this.network.name : 'Booking Engine' },
        { hid: 'og:site_name', name: 'og:site_name ', content: this.network?.name ? this.network.name : 'Booking Engine' },
        { hid: 'og:url', property: 'og:url ', content: this.canonical },
        { charset: 'utf-8' },
        { hid: 'keywords', name: 'keywords', content: `'book, best price, hospitality, hotel, b&b, apartments, ${this.network.name}'` },
        { name: 'theme-color',  content: this.network.primary_color}
      ],
      bodyAttrs: {
        class: this.$device.isMobile ? 'has-navbar-fixed-top has-spaced-navbar-fixed-top home-layout' : 'has-navbar-fixed-top has-spaced-navbar-fixed-top home-layout has-desktop-home-search-bar'
      },
      link: [
        {
          hid: 'icon',
          rel: 'icon',
          type: this.network.favicon ? 'image/svg+xml' : 'image/png' ,
          href: !this.network.favicon ? `data:image/svg+xml,<svg viewBox="0 0 250 250" xmlns="http://www.w3.org/2000/svg">
                                                              <circle cx="125" cy="125" r="120" fill="${encodeURIComponent(this.network.primary_color)}"/>
                                                              <text x="50%" y="52%" text-anchor="middle" fill="white" font-size="140px" font-weight="bolder" font-family="sans-serif" dy=".3em">
                                                                ${this.network.name.split(" ").shift().charAt(0)}${this.network.name.split(" ").pop().charAt(0)}
                                                              </text>
                                                            </svg>`
                                                            :
                                                            this.network.favicon.full_path,
        },
        {
           rel: 'canonical',
           href: this.canonical
        },
        {
          //HEADERS FONT LOADER
          rel: 'stylesheet',
          href: this.network.font_title.length != 0 ? this.network.font_title.link : ''
        },
        {
          //BODY FONT LOADER
          rel: 'stylesheet',
          href: this.network.font_body.length != 0 ? this.network.font_body.link : ''
        }
      ],
    };
  },
  data() {
    return {
      googleApiKey: env.GOOGLE_API_KEY,
      ready: false,
      title_font: false,
      body_font: false,
      canonical: '',
      popup: false,
    };
  },
  mounted() {
    
    this.ready = true;
    if (!this.isBrowser) {
      this.$store.commit(SET_BROWSER, true);
    }

    this.checkPopupCookie()
    this.cookieLaw();

    this.canonical = this.getCanonical
  },
  methods: {
    checkPopupCookie() {
      const cookies = new Cookies();
      if (!cookies.get('popupShown') && !!this.networkConfig && !!this.networkConfig.popup) {

        this.popup = !!this.networkConfig ? !!this.networkConfig.popup : false;

        cookies.set('popupShown', 'true');
      }
    }
  },
  computed: {
    ...mapState({
      isBrowser: state => state.app.isBrowser,
      network: state => state['booking-networks'].getBookingNetworksRequest.data,
    }),
    getCanonical() {
      return window.location.href
    }
  }
};
