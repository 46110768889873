import { mapState } from "vuex";

import {
  ADD_TO_CART,
  REMOVE_FROM_CART,
  EMPTY_CART,
} from '~/store/cart/constants';

import gtm from '~/mixins/gtm';

export default {
  mixins: [
    gtm
  ],
  methods: {
    async addToCart(item) {
      await this.$store.dispatch(ADD_TO_CART, item);
      await this.gtmAddToCart(item.id);
    },
    async removeFromCart(id) {
      await this.gtmRemoveFromCart(id);
      await this.$store.dispatch(REMOVE_FROM_CART, id);
    },
    async emptyCart() {
      await this.$store.dispatch(EMPTY_CART);
    },
    isAddedCart(roomType) {
      return this.cartItems.filter(item => {
        return item.id === roomType.id && item.guests === roomType.guests
      }).length > 0;
    },
    /**
     * Not ok when property is not occupational, guest check is required
     * @param {*} id 
     * @returns 
     */
    quantityCart(id) {
      let quantity = 0;

      this.cartItems.forEach(item => {
        if (!id || item.id === id) {
          quantity += item.quantity;
        }
      });

      return quantity;
    },
    quantityCartGuest(roomType) {
      let quantity = 0;

      this.cartItems.forEach(item => {
        if (
          !roomType ||
          (item.id === roomType.id && item.guests === roomType.guests)
        ) {
          quantity += item.quantity;
        }
      });

      return quantity;
    },
  },
  computed: {
    ...mapState({
      cartItems: state => state.cart.items,
    }),
  }
}
