export default {
  methods: {
    propertiesRoute(query) {
      return this.localePath({
        name: 'properties',
        query
      })
    },
    singleRoute(id, query) {
      return this.localePath({
        name: "properties-id",
        params: { id },
        query
      });
    },
    singleRouteByProperty(property, query) {
      const slug = property.is_numeric ? property.id : property.slug;

      return this.localePath({
        name: "properties-id",
        params: { id: slug },
        query
      });
    },
    bookRouteByProperty(property, query) {
      const slug = property.is_numeric ? property.id : property.slug;

      return this.localePath({
        name: "book-id",
        params: { id: slug },
        query
      })
    }
  }
}
